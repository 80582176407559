.cart {
    color: white;
    font-weight:bolder;
    font-size: larger;
}

.celebrate-with-us {
    width: 100vw;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: linear-gradient(to bottom right, #a71634, #333f94);
    font-size: larger;
}
.paragraphs {
    font-family:'Times New Roman', Times, serif;
    color: white;
    text-align: center;
}
.djmc-container {
    background-image: url('../../../public/djimg.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: repeat-y;
}
.heading-text {
    font-family:'Times New Roman', Times, serif;
    font-size: 4.5vmin;
    font-weight: bolder;
    opacity: .8;
    font-style: oblique;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 1px;
}
.heading-subtext {
    font-family:'Times New Roman', Times, serif;
    font-size: 4.5vmin;
    font-weight: bold;
    opacity: .8;
    font-style: oblique;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
}
.header {
    width: 100vw;
    height: 50vh;
    background-image: url('../../../public/djimg.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: repeat-y;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-text {
    font-family:'Times New Roman', Times, serif;
    font-size: 4.5vmin;
    font-weight: bolder;
    color: white;
    opacity: .8;
    font-style: oblique;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
}
.header-content {
    color: whitesmoke;
    text-align: center;
    margin-bottom: 100px;
}
.home-card{
    background-image: url('../../../public/djimg.jpg');
    background-size: cover;
    background-position: bottom;
    background-repeat: repeat-y;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
}
.home-logo {
    display: flex;
    max-width: 80vw;
    width: 400px;
    filter: brightness(0) invert(1) ;
    opacity: 0.6;
}
.home-text {
    font-family:'Times New Roman', Times, serif;
    font-size: 4.5vmin;
    font-weight: bolder;
    opacity: .8;
    font-style: oblique;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
    color: whitesmoke;
    text-align: center;
    margin-bottom: 100px;
}
.image-border {border: 1px solid #949191;
}
.line {
    width: 150px;
    height: 4px;
    background: #fc036b;
    margin: 10px auto;
    border-radius: 5px;
}
.line-dotted {
    width: 150px;
    height: 2px;
    background: #fc036b;
    opacity: 10;
    margin: 10px auto;
    border-radius: 5px;
}
.nav-items{
    color:#fff;
    font-size: large;
    font-weight: bolder;
}
.nav-items a {
    color:#ced80d;
}
.nav-items-dropdown{
    background-color: #333f94;
}
.our-services {
    width: 100vw;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: linear-gradient(to top left , #a71634, #333f94);
}
.our-services h1 {
    font-family:'Times New Roman', Times, serif;
    color: white;
    font-weight: 900;
    margin-bottom: 50px;
}
.paragraph-text {
    font-family:'Times New Roman', Times, serif;
    /* font-size: 4.5vmin; */
    font-weight:lighter;
    opacity: .8;
    font-style: oblique;
    text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
}
.pictures {
    max-width: 80vw;
}
ul {
    list-style-type: none;
}

@media (max-width:750px) {
    .celebrate-with-us {
        height: 70vh;
    }
}
@media (max-width:576px) {
    .celebrate-with-us {
        font-size:small;
    }
    .our-services{
        height: 40vh;
    }
}
@media (max-width: 1080px){
}
